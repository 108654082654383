import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinComponent from '../components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuSymbolSkin/VerticalAnchorsMenuSymbolSkin.skin';
import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinController from '../components/VerticalAnchorsMenu/viewer/VerticalAnchorsMenu.controller';


const VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinComponent,
  controller: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinController
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin
};

